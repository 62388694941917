import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function HedgeTalk({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('HedgeTalk');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>HedgeTalk: AMA</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default HedgeTalk;

const DATA = [
    {
        title: 'What is HedgeTalk?',
        content: 'HedgeTalk is an interactive Podcast. We open up the floor to world-class leaders in business, marketing, blockchain and software services'
    },
    {
        title: 'Where can we apply for HedgeTalk?',
        content: 'If you want to apply for HedgeTalk, feel free to <a href="/contact">contact us</a>.'
    },
    {
        title: 'Who will be the host for HedgeTalk?',
        content: 'HedgePay CEO, Mason Peck will the host for HedgeTalk'
    },
    {
        title: 'What are the audience like?',
        content: 'Min were 80+ and maximum were 700+'
    },
    {
        title: 'Where will HedgePay broadcast at?',
        content: 'Telegram or Twitter'
    }
]