import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../../components/layoutDoc";
import Seo from "../../../components/seo";
import Component from '../../../components/faq/HedgeTalk'

function HedgeTalk() {
    return (
        <Layout page="Faq" pageInner="HedgeTalk">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="FAQ | HedgeTalk" />
            <Container fluid="lg" className="py-md-5 py-4">
                <Component />
            </Container>
        </Layout>
    );
}

export default HedgeTalk;
